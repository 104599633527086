import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import MetaTags from 'react-meta-tags';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoMenuCardPage from './NoMenuCardPage.js';
import FavoriteIcon from '@material-ui/icons/Favorite';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import grey from '@material-ui/core/colors/grey';
import { fade, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { appActions } from './../state/actions/app';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import MenuCardImagesGrid from './MenuCardImagesGrid.js';
import Footer from '../PromoUI/Footer.js';
import AppExperience from '../PromoUI/AppExperience.js';
import { connect, useSelector } from "react-redux";
const formatCurrency = require("country-currency-map").formatCurrency;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appIcon: {
        marginRight: theme.spacing(1),
        alignItems: 'center',
        position: 'static'
    },
    addMenuMessage: {
        marginTop: theme.spacing(10),
    },
    appBar: {
        position: 'sticky',
        background: grey[50],
        zIndex: 1
    },
    mainView: {
        backgroundColor: grey[100],
        paddingBottom: theme.spacing(10),
        minHeight: 550
    },
    title: {
        flexGrow: 1,
    },
    iconButton: {
        padding: 10,
    },
    paper: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    thumbnail: {
        border: '1px solid gray',
        borderRadius: 15,
        width: 192,
        height: 192,
        [theme.breakpoints.only('xs')]: {
            width: 152,
            height: 152
        },
        [theme.breakpoints.only('sm')]: {
            width: 152,
            height: 152
        },
    },
    menuContent: {
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(5),
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: 5,
            marginBottom: 10,
        }
    },
    itemActions: {
        width: 300,
        display: 'flex',
        alignItems: 'center',
    },
    sectionContent: {
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    primaryButton: {
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[700],
        },
        marginTop: 40,
        marginBottom: 30,
        alignItems: 'bottom',
        color: 'white'
    },
    restaurantName: {
        marginTop: theme.spacing(1),
    },
    cardGrid: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(8),
    },
    cardItem: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 280,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 300,
        },
        [theme.breakpoints.only('md')]: {
            maxWidth: 400,
        },
        [theme.breakpoints.only('lg')]: {
            maxWidth: 500,
        },
        margin: theme.spacing(1),
        borderRadius: 15,
    },
    likeCount: {
        marginLeft: theme.spacing(1)
    },
    circularProgress: {
        margin: 'auto',
        marginRight: 'auto',
        marginTop: '250px',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.25),
        },
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        color: grey[700],
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'black',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(5)}px)`,
        transition: theme.transitions.create('width'),
        color: 'black',
        maxWidth: '12ch',
        [theme.breakpoints.down('sm')]: {
            width: '8ch',
            marginLeft: theme.spacing(1),
            '&:focus': {
                width: '12ch',
            },
        },
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const ItemCard = (props) => {
    const classes = useStyles();
    const { foodItem } = props;
    let currencyVal;
    if (foodItem.price && foodItem.currency) {
        currencyVal = formatCurrency(foodItem.price, foodItem.currency.toUpperCase()); //=> '$100,000';
    }

    return (
        <Card className={classes.cardItem}>
            <CardActionArea>
                <CardContent>  
                    <Typography align="left" variant="h5" color="textPrimary" component="p" gutterBottom>
                        {foodItem.name}
                    </Typography>
                    {foodItem.description &&
                        <Typography align="left" variant="body1" color="textSecondary" component="p">
                            {foodItem.description}
                        </Typography>
                    }
                    <Grid container>
                        <Grid className={classes.itemActions} item align="left" xs={9} sm={9} md={9}>
                            <FavoriteIcon style={{ color: red[500] }} />
                            <Typography className={classes.likeCount} variant="h6" color="textPrimary" component="p">
                                {foodItem.likes}
                            </Typography>
                        </Grid>
                        <Grid item align="right" xs={3} sm={3} md={3}>
                            <Typography variant="h6" color="textPrimary" component="p">
                                {currencyVal}
                            </Typography>
                        </Grid>
                        
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

const SectionCard = (props) => {
    const classes = useStyles();
    const { section } = props;
    return (
        <Container>
            {/* End hero unit */}
            <Typography variant="h4" align="center" color="textPrimary">
                {section.name}
            </Typography>
            {section.description &&
                <Typography variant="h6" align="center" color="textSecondary" paragraph>
                    {section.description}
                </Typography>
            }
            <Grid className={classes.sectionContent} container spacing={1} elevation={1}>
                {section.foodItems.map((foodItem, index) => (
                    <Grid item align="center" xs={12} md={6} sm={6} lg={6}>
                        <ItemCard foodItem={foodItem}/>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

function MenuCardPage(props) {
    const classes = useStyles();
    // Get the ids from the url provided by react-router
    const ids = props.match.params.ids;
    // Split the ids as it contains both restaurantId and foodItemId
    const restaurantId = (ids && ids.length) ? ids.split('_')[0] : '';
    const restaurant = useSelector(state => state.app.mainMenu);
    const isDetailLoading = useSelector(state => state.app.isDetailLoading);
    const isMenuLoading = useSelector(state => state.app.isMenuLoading);
    const isMenuAlbumLoading = useSelector(state => state.app.isMenuAlbumLoading);
    const detailedMenu = useSelector(state => state.app.detailedMenu);
    const menuImages = useSelector(state => state.app.menuImages);
    const nextPageToken = useSelector(state => state.app.nextPageToken);
    const [searchQuery, setSearchQuery] = React.useState(null);

    const createMenuCard = (restaurant, foodItems, searchQuery) => {
        // Build map of sections
        var sectionMap = {};
        var resultMap = {};
        var result = [];
        // var foodItemId = this.props.foodItemId;

        for (var i = 0; i < restaurant.sections.length; i++) {
            var sectionItem = restaurant.sections[i];
            // Store food Items under it here
            sectionItem.foodItems = [];
            sectionMap[String(sectionItem.section_number)] = sectionItem;
        }

        for (var j = 0; j < foodItems.length; j++) {
            var foodItem = foodItems[j];
            // Get section
            // if (foodItemId && foodItemId === foodItem.id && !this.props.nextPageToken) {
                // Do it only if there is no next page as we want it to scroll after loading all items
                // foodItem.isShared = true;
            // }
            let sectionVal = sectionMap[foodItem.section];
            if (sectionVal) {
                // Store food Items under it here
                if ((searchQuery && foodItem.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1) || !searchQuery) {
                    sectionVal.foodItems.push(foodItem);
                }
                // Put it back
                if (sectionVal.foodItems.length > 0) {
                    resultMap[String(sectionVal.section_number)] = sectionVal;
                }
            }
        }

        for (i = 0; i < restaurant.sections.length; i++) {
            var sectionItemVal = restaurant.sections[i];
            var resultSection = resultMap[sectionItemVal.section_number];
            if (resultSection) {
                // Sort foodItems based on position
                resultSection.foodItems.sort((a, b) => (parseInt(a.position) > parseInt(b.position)) ? 1 : ((parseInt(a.position) < parseInt(b.position)) ? -1 : 0));
                // Get from map and push into result
                result.push(resultSection);
            }
        }
        // Sort menu Sections based on position
        result.sort((a, b) => (parseInt(a.position) > parseInt(b.position)) ? 1 : ((parseInt(a.position) < parseInt(b.position)) ? -1 : 0));
        return result;
    };

    const onSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    }
    
    useEffect(() => {
        // Your code here
        if (!restaurant && restaurantId) {
            props.getMainMenu(restaurantId);
            props.getMenuImages(restaurantId);
        }

        if (restaurant && restaurant.sections && !detailedMenu) {
            props.getFoodItems(restaurant.id);
        }

        if (detailedMenu && nextPageToken) {
            // Call next page only if there are some menu cards & if there is next page
            props.getNextPage(restaurant.id, nextPageToken, detailedMenu);
        }

    }, [props, restaurant, detailedMenu, nextPageToken]);

    const getAddress = () => {
        let address = restaurant && restaurant.address;
        const addrSep = ', ';
        if (address) {
            return address.street + addrSep + address.city + addrSep + address.state;
        } else {
            return '';
        }
    }

    let menuSections = []

    if (restaurant && detailedMenu) {
        menuSections = createMenuCard(restaurant, detailedMenu, searchQuery);
    }

    let metaData = <div></div>;
    let title = "Chego - Restaurant menu cards & ratings";
    const description = "Browse restaurant menu cards. " +
        "Search food items and see what people love. " +
        "Rate food items and share with friends.";
    
    if ((!restaurant || (restaurant && !restaurant.id)) && !isDetailLoading) {
        return (
            <NoMenuCardPage restaurantId={restaurantId} />
        );
    }
    
    if (restaurant && restaurant.name) {
        title = restaurant.name + " – " + "Chego";
    }
    
    if (restaurant && restaurant.address) {
        metaData =
            (<div>
                <div itemscope itemtype="http://schema.org/Restaurant">
                    <span itemprop="name">{restaurant.name}</span>
                    <div itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
                        <span itemprop="streetAddress">{restaurant.address.street}</span>
                        <span itemprop="addressLocality">{restaurant.address.city}</span>
                        <span itemprop="addressRegion">{restaurant.address.state}</span>
                        <span itemprop="postalCode">{restaurant.address.zipcode}</span>
                    </div>
                    <div itemprop="geo" itemscope itemtype="http://schema.org/GeoCoordinates">
                        <span itemprop="latitude">{restaurant.location.lat}</span>
                        <span itemprop="longitude">{restaurant.location.lon}</span>
                        <div itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
                            <span itemprop="streetAddress">{restaurant.address.street}</span>
                            <span itemprop="addressLocality">{restaurant.address.city}</span>
                            <span itemprop="addressRegion">{restaurant.address.state}</span>
                            <span itemprop="postalCode">{restaurant.address.zipcode}</span>
                        </div>
                    </div>
                </div>

            </div>);
    }

    return (
        <div className={classes.root}>
            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:image" content="https://storage.googleapis.com/palate-1301.appspot.com/ChegoIcon.png" />
                {metaData}
            </MetaTags>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <a href="/">
                        <Grid key="icon" className={classes.appIcon} item>
                            <img width="32" height="32" alt="Chego logo" alt="Chego logo containing a menu card and a fork."
                                src="https://storage.googleapis.com/palate-1301.appspot.com/ChegoIcon.png" />
                        </Grid>
                    </a>
                    <Typography color="textPrimary" variant="h6" className={classes.title}>
                        Chego
                    </Typography>
                    {restaurant && restaurant.sections.length > 0 && 
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onChange={onSearchQueryChange}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    }
                    <IconButton className={classes.iconButton} aria-label="open edit page"
                        href={"https://manager.chego.app/menucards/" + restaurantId} target="_blank">
                        <EditIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <main>
                {/* Hero unit */}
                {(isDetailLoading || isMenuLoading || isMenuAlbumLoading) ? 
                    <Grid container className={classes.mainView} spacing={1} elevation={5}>
                        <CircularProgress className={classes.circularProgress} />
                    </Grid>
                    :
                    <React.Fragment>
                        <Grid container className={classes.mainView} spacing={1} elevation={5}>
                            <Grid className={classes.paper} item xs={12} sm={12} md={4} elevation={2} square>
                                <img className={classes.thumbnail} alt="Chego icon" src={(restaurant && restaurant.thumbnail) || 'https://storage.googleapis.com/chego-manager.appspot.com/MissingMenuCard.png'} />
                                <Typography variant="h5" align="center" color="textPrimary" className={classes.restaurantName} gutterTop >
                                    {restaurant && restaurant.name}
                                </Typography>
                                <Typography variant="h6" align="center" color="textSecondary" component="p">
                                    {getAddress()}
                                </Typography>
                            </Grid>
                            <Grid className={classes.menuContent} item xs={12} sm={12} md={8} elevation={2} square>
                                <MenuCardImagesGrid />
                                {menuSections.map((section, index) => (
                                    <SectionCard section={section} />
                                ))}
                                {menuImages.length === 0 && detailedMenu.length === 0 && !isMenuLoading &&
                                    <Container className={classes.addMenuMessage} maxWidth="sm">
                                        <Typography  variant="h5" color="textPrimary" align="center" gutterBottom>
                                            Chego doesn't have this menu card.
                                        </Typography>
                                        <div className={classes.heroButtons}>
                                            <Grid container spacing={2} justify="center">
                                                <Grid item>
                                                    <Button variant="contained" className={classes.primaryButton}
                                                        target="_blank"
                                                        href={"https://manager.chego.app/menucards/" + restaurantId}
                                                        startIcon={<OpenInNewIcon />}>
                                                        Add Menu Card
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Container>
                                }
                            </Grid>
                        </Grid>
                        <AppExperience />
                    </React.Fragment>
                }
            </main>
            <Footer/>
        </div>
    );
}

export default connect(null, appActions)(MenuCardPage);
