import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../PromoUI/Footer.js';
import lightBlue from "@material-ui/core/colors/lightBlue";
import grey from '@material-ui/core/colors/grey';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    appIcon: {
        marginRight: theme.spacing(1),
        alignItems: 'center',
        position: 'static'
    },
    appBar: {
        position: 'sticky',
        background: grey[50],
        zIndex: 1
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
        minHeight: 500,
    },
    heroButtons: {
        marginTop: theme.spacing(2),
    },
    openButton: {
        backgroundColor: lightBlue[400],
        '&:hover': {
            backgroundColor: lightBlue[100],
        },
        marginTop: 20,
        marginBottom: 30,
        alignItems: 'bottom',
        color: 'white'
    },
    message: {
        marginTop: theme.spacing(40)
    },
    mainView: {
        backgroundColor: grey[100],
        paddingBottom: theme.spacing(20),
        minHeight: 550,
        flex: 'row'
    },
}));

export default function PrivacyPage(props) {
    const classes = useStyles();
    const { restaurantId } = props;

    return (
        <React.Fragment>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <a href="/">
                        <Grid key="icon" className={classes.appIcon} item>
                            <img width="32" height="32" alt="Chego logo" alt="Chego logo containing a menu card and a fork."
                                src="https://storage.googleapis.com/palate-1301.appspot.com/ChegoIcon.png" />
                        </Grid>
                    </a>
                    <a href="/">
                        <Typography color="textPrimary" variant="h6" className={classes.title}>
                            Chego
                        </Typography>
                    </a>
                </Toolbar>
            </AppBar>
            <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="left" gutterBottom>
                        Privacy Policy
                    </Typography>
                    <Typography variant="p" color="textPrimary" align="left">
                        Nayan Vemula built the Chego app as a Free app. This SERVICE is provided by Nayan Vemula at no cost and is intended for use as is.
                        This page is used to inform visitors regarding my policies with the collection, use, and disclosure of Personal Information if anyone decided to use my Service.
                        If you choose to use my Service, then you agree to the collection and use of information in relation to this policy. The Personal Information is not collected by the Service. I can’t access your personal information as described in this Privacy Policy.
                    </Typography>
                    <br/>
                    <Typography variant="p" color="textPrimary">
                        The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at Chego unless otherwise defined in this Privacy Policy.
                    </Typography>
                </Container>
                <br />
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="left" gutterBottom>
                        Information Collection and Use
                    </Typography>
                    <Typography variant="p" color="textPrimary">
                        Chego doesn’t collect any personal data. Chego uses iCloud to store your data privately and securely so that only you can see your private data.
                        The information that I request will be retained on your device and is not collected by me in any way.
                        The app does not use any third party services that collect your data.
                    </Typography>
                </Container>
                <br />
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="left" gutterBottom>
                        Log Data
                    </Typography>
                    <Typography variant="p" color="textPrimary">
                        I want to inform you that whenever you send feedback using my Service, I collect data and information on your phone called Log Data.
                        This Log Data may include information such as your  device model name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.
                        You can always view this data while sending feedback from the app.
                    </Typography>
                </Container>
                <br />
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="left" gutterBottom>
                        Cookies
                    </Typography>
                    <Typography variant="p" color="textPrimary">
                        Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
                        These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
                        This Service does not use these “cookies” explicitly.
                    </Typography>
                </Container>
                <br />
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="left" gutterBottom>
                        Service Providers
                    </Typography>
                    <Typography variant="p" color="textPrimary">
                        I don’t employ any third-party companies and individuals to bring this Service.
                        I want to inform users of this Service that Chego and third parties have no access to your Personal Information.
                    </Typography>
                </Container>
                <br />
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="left" gutterBottom>
                        Security
                    </Typography>
                    <Typography variant="p" color="textPrimary">
                        The Service uses anonymous id to send ratings to the server and the information can’t be traced to any individual.
                        The Service doesn’t require an account to use.
                        The Service uses iCloud to store user’s data privately and securely so only the user can see their data.
                        The user’s data is not stored in Chego’s servers.
                    </Typography>
                </Container>
                <br />
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="left" gutterBottom>
                        Links to Other Sites
                    </Typography>
                    <Typography variant="p" color="textPrimary">
                        This Service may contain links to other sites.
                        If you click on a third-party link, you will be directed to that site.
                        Note that these external sites are not operated by me.
                        Therefore, I strongly advise you to review the Privacy Policy of these websites.
                        I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                    </Typography>
                </Container>
                <br />
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="left" gutterBottom>
                        Children’s Privacy
                    </Typography>
                    <Typography variant="p" color="textPrimary">
                        This Service does not address anyone under the age of 13.
                        I do not collect personally identifiable information from any user and that includes children under 13.
                        In the case I discover that a child under 13 has provided the app with personal information, I ask them to delete the app data from iCloud.
                        If you are a parent or guardian and you are aware that your child has provided the app with personal information, please contact me so that I will be able to guide you to do the necessary actions as I can’t access the data.
                    </Typography>
                </Container>
                <br />
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="left" gutterBottom>
                        Changes to This Privacy Policy
                    </Typography>
                    <Typography variant="p" color="textPrimary">
                        I may update our Privacy Policy from time to time.
                        Thus, you are advised to review this page periodically for any changes.
                        I will notify you of any changes by posting the new Privacy Policy on this page.
                        These changes are effective immediately after they are posted on this page.
                    </Typography>
                </Container>
                <br />
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="left" gutterBottom>
                        Contact Us
                    </Typography>
                    <Typography variant="p" color="textPrimary">
                        If you have any questions or suggestions about my Privacy Policy, do not hesitate to 
                         <a href="https://docs.google.com/forms/d/e/1FAIpQLSe99oi3pvKJSmCNzActGeoOI8KHCbrSeAcE4_B7KIZOGT2J8g/viewform?usp=sf_link"> contact me</a>
                    </Typography>
                </Container>
            </div>
            <Footer/>
        </React.Fragment>
    );
}