import axios from 'axios';

const ACTIONS = {
    MAIN_MENU: 'MAIN_MENU',
    DETAILED_MENU: 'DETAILED_MENU',
    SET_MENUCARD_ID: 'SET_MENUCARD_ID',
    GET_FOOD_ITEMS: 'GET_FOOD_ITEMS',
    GET_NEXT_PAGE: 'GET_NEXT_PAGE',
    GET_MENU_IMAGES: 'GET_MENU_IMAGES',
    ERROR: 'ERROR'
};

const serverUrl = process.env.REACT_APP_SERVER_URL;
const apiKey = process.env.REACT_APP_CHEGO_WEB_KEY;

let appActions = function(dispatch) {
    return {
        getMainMenu: (restaurantId) => {
            const restaurantUrl = serverUrl + "/_ah/api/fitl/v1/restaurant?id=" + restaurantId + "&key=" + apiKey;
            axios.get(restaurantUrl)
            .then(function (response) {
                dispatch({
                    type: ACTIONS.MAIN_MENU,
                    mainMenu: response.data
                });
            })
            .catch(function (error) {
                // Stop spinner
                dispatch({
                    type: ACTIONS.ERROR,
                });
                console.log(error);
            });
        },
        getFoodItems: (restaurantId) => {
            const restaurantUrl = serverUrl + "/_ah/api/fitl/v1/menucard?limit=100&restaurant="+
                restaurantId + "&key="+ apiKey;
            axios.get(restaurantUrl)
            .then(function (response) {
                dispatch({
                    type: ACTIONS.GET_FOOD_ITEMS,
                    detailedMenu: response.data.items,
                    nextPageToken: response.data.nextPageToken
                });
            })
            .catch(function (error) {
                // Stop spinner
                console.log(error);
            });
        },
        getNextPage: (restaurantId, nextPageToken, detailedMenu) => {
            const restaurantUrl = serverUrl + "/_ah/api/fitl/v1/menucard?limit=100&restaurant="+
                restaurantId + "&key="+ apiKey + "&pageToken=" + nextPageToken;
            axios.get(restaurantUrl, detailedMenu)
            .then(function (response) {
                var newMenu = detailedMenu.concat(response.data.items);
                dispatch({
                    type: ACTIONS.GET_NEXT_PAGE,
                    detailedMenu: newMenu,
                    nextPageToken: response.data.nextPageToken
                });
            })
            .catch(function (error) {
                // Stop spinner
                console.log(error);
            });
        },
        getMenuImages: (restaurantId) => {
            const restaurantUrl = serverUrl + "/_ah/api/fitl/v1/menucardimages?restaurant=" +
                restaurantId + "&key="+ apiKey;
            axios.get(restaurantUrl)
            .then(function (response) {
                dispatch({
                    type: ACTIONS.GET_MENU_IMAGES,
                    menuImages: response.data.items,
                });
            })
            .catch(function (error) {
                // Stop spinner
                console.log(error);
            });
        },
        setMenuCardId: (restaurantId, foodItemId) => {
            if (restaurantId) {
              dispatch({type: ACTIONS.SET_MENUCARD_ID, restaurantId: restaurantId, foodItemId: foodItemId});
            }
        }
    }
};
export {appActions, ACTIONS};
