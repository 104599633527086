import {ACTIONS} from '../actions/app';

const initialState = {
    mainMenu: null,
    detailedMenu: null,
    nextPageToken: null,
    isDetailLoading: true,
    isMenuLoading: true,
    isMenuAlbumLoading: true,
    menuData: null,
    menuImages: null,
    restaurantId: null,
    foodItemId: null,
    pageNotFound: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case ACTIONS.MAIN_MENU:
            return Object.assign({}, state, {
                mainMenu: action.mainMenu,
                isDetailLoading: false
            });
        case ACTIONS.GET_FOOD_ITEMS:
            return Object.assign({}, state, {
                detailedMenu: action.detailedMenu,
                nextPageToken: action.nextPageToken,
                isMenuLoading: false
            });
        case ACTIONS.GET_NEXT_PAGE:
            return Object.assign({}, state, {
                detailedMenu: action.detailedMenu,
                nextPageToken: action.nextPageToken
            });
        case ACTIONS.DETAILED_MENU:
            return Object.assign({}, state, {
                detailedMenu: action.detailedMenu,
                menuData: action.menuData
            });
        case ACTIONS.GET_MENU_IMAGES:
            return Object.assign({}, state, {
                menuImages: action.menuImages,
                isMenuAlbumLoading: false
            });
        case ACTIONS.ERROR:
            return Object.assign({}, state, {
                pageNotFound: true,
                isDetailLoading: false,
                isMenuAlbumLoading: false,
                isMenuLoading: false
            });
        case ACTIONS.SET_MENUCARD_ID:
            return Object.assign({}, state, {
                restaurantId: action.restaurantId,
                foodItemId: action.foodItemId
            });
        default:
            return state;
    }
}
