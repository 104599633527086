import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PrivacyPage from './PromoUI/PrivacyPage.js';
import AvailabilityPage from './PromoUI/AvailabilityPage.js';
import HomePage from './PromoUI/HomePage.js';
import MenuCardPage from './PlacesUI/MenuCardPage.js';
import AndroidLogin from './LoginUI/AndroidLogin.js';
import AndroidLoginSuccessPage from './LoginUI/AndroidLoginSuccessPage';

class App extends Component {
  render() {
    return (
      <main>
        <Router>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/android-login/' component={AndroidLogin}/>
            <Route exact path='/android-login-success' component={AndroidLoginSuccessPage}/>
            <Route exact path='/privacy/' component={PrivacyPage} />
            <Route exact path='/availability/' component={AvailabilityPage} />
            <Route path='/menucards/:city/:name/:ids' component={MenuCardPage}/>
            <Route path='/menucards/:ids' component={MenuCardPage} />
            <Route exact path='/*' component={HomePage} />
          </Switch>
        </Router>
      </main>
    );
  }
}

export default App;
