import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import orange from "@material-ui/core/colors/orange";
import Footer from './Footer.js';
import lightBlue from "@material-ui/core/colors/lightBlue";

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
    },
    heroImage: {
        [theme.breakpoints.only('xs')]: {
            width: 360,
            height: 240
        },
        [theme.breakpoints.up('sm')]: {
            width: 640,
            height: 480
        },
        [theme.breakpoints.up('md')]: {
            width: 1040,
            height: 640
        }
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    googlePlayIcon: {
        marginTop: '-20px',
    },
    openButton: {
        backgroundColor: lightBlue[400],
        '&:hover': {
            backgroundColor: lightBlue[100],
        },
        marginTop: 10,
        marginBottom: 30,
        alignItems: 'bottom',
        color: 'white'
    },
    learnMoreButton: {
        marginTop: 10,
        marginBottom: 30,
        alignItems: 'bottom',
    },
}));

export default function HomePage() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <main>
                {/* Hero unit */}
                <div className={classes.heroContent}>
                    <Container>
                        <Grid container justify="center">
                            <img
                                width="64"
                                height="64"
                                alt="Chego logo containing a menu card and a fork."
                                src="https://storage.googleapis.com/palate-1301.appspot.com/ChegoIcon.png"
                            />
                        </Grid>
                        <Typography variant="h3" align="center" color={orange[500]} gutterBottom>
                            Chego
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" component="p">
                            Browse restaurant menu cards. <br />
                            Search food items & see what's popular.<br />
                            Rate food items & share with friends.
                        </Typography>
                        <div className={classes.heroButtons}>
                            <Grid container spacing={1} justify="center">
                                <Grid item>
                                    <a target="_blank" href='https://apps.apple.com/us/app/chego/id1434660059?ls=1&mt=8'>
                                        <img width="145" height="60" alt="Download on the App Store"
                                            src="https://storage.googleapis.com/chego-web.appspot.com/AppStoreOrder.svg" />
                                    </a>
                                </Grid>
                                <Grid item className={classes.googlePlayIcon}>
                                    <a target="_blank" href='https://play.google.com/store/apps/details?id=com.happy.Chego'>
                                        <img width="180" height="100"  alt="Get it on Google Play"
                                            src="https://storage.googleapis.com/chego-web.appspot.com/GooglePlayBadge.svg" />
                                    </a>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container spacing={2} justify="center">
                            <img className={classes.heroImage} src="https://storage.googleapis.com/chego-web.appspot.com/ios-devices.png" />
                        </Grid>
                    </Container>
                </div>
                <Container className={classes.cardGrid} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container justify="center">
                        <img
                            width="64"
                            height="64"
                            alt="Chego Manager logo containing a menu card and a fork."
                            src="https://storage.googleapis.com/chego-manager.appspot.com/ChegoManagerIcon.png"
                        />
                    </Grid>
                    <Typography variant="h3" align="center" color={orange[500]} gutterBottom>
                        Chego Manager
                        </Typography>
                    <Typography variant="h6" align="center" color="textSecondary" paragraph>
                        Manage Chego place listings <br />
                        Add restaurant menu cards <br />
                        Print QR codes <br />
                    </Typography>
                    <div className={classes.heroButtons}>
                        <Grid container spacing={2} justify="center">
                            <Grid item>
                                <Button variant="contained" className={classes.learnMoreButton}
                                    href="https://manager.chego.app/overview"
                                >
                                    Learn More
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" className={classes.openButton}
                                    target="_blank"
                                    href="https://manager.chego.app"
                                    startIcon={<OpenInNewIcon />}>
                                    Open Website
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={2} justify="center">
                        <img className={classes.heroImage} src="https://storage.googleapis.com/chego-manager.appspot.com/ChegoManagerWeb.png" />
                    </Grid>
                </Container>
            </main>
            <Footer/>
        </React.Fragment>
    );
}