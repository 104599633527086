import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import orange from "@material-ui/core/colors/orange";
import Footer from './Footer.js';
import lightBlue from "@material-ui/core/colors/lightBlue";

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 2),
    },
    heroButtons: {
        marginTop: theme.spacing(2),
    },
    heroImage: {
        marginTop: theme.spacing(3),
        width: 310,
        height: 400
    },
    googlePlayIcon: {
        marginTop: '-20px',
    },
    downloadMessage: {
        marginTop: theme.spacing(2)
    }
}));

export default function AppExperience() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
                {/* Hero unit */}
                <div className={classes.heroContent}>
                    <Container maxWidth="sm">
                        <Grid container justify="center">
                            <img
                                width="48"
                                height="48"
                                alt="Chego logo containing a menu card and a fork."
                                src="https://storage.googleapis.com/palate-1301.appspot.com/ChegoIcon.png"
                            />
                        </Grid>
                        <Typography className={classes.downloadMessage} variant="h4" align="center" color={orange[500]}>
                            Get the Chego app
                        </Typography>
                        <Typography variant="h6" align="center" color="textSecondary" component="p">
                            Browse restaurant menu cards. <br />
                            Search food items & see what's popular.<br />
                            Rate food items & share with friends.
                        </Typography>
                        <Grid container spacing={2} justify="center">
                            <img className={classes.heroImage} src="https://storage.googleapis.com/chego-web.appspot.com/LoveDislikeFeature.png" />
                        </Grid>
                        <div className={classes.heroButtons}>
                            <Grid container spacing={2} justify="center">
                                <Grid item>
                                    <a target="_blank" href='https://apps.apple.com/us/app/chego/id1434660059?ls=1&mt=8'>
                                        <img width="145" height="60" alt="Download on the App Store"
                                            src="https://storage.googleapis.com/chego-web.appspot.com/AppStoreOrder.svg" />
                                    </a>
                                </Grid>
                                <Grid item className={classes.googlePlayIcon}>
                                    <a target="_blank" href='https://play.google.com/store/apps/details?id=com.happy.Chego'>
                                        <img width="180" height="100" alt="Get it on Google Play"
                                            src="https://storage.googleapis.com/chego-web.appspot.com/GooglePlayBadge.svg" />
                                    </a>
                                </Grid>
                            </Grid>
                        </div>
                    </Container>
                </div>
        </React.Fragment>
    );
}