import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'© Chego'}
            {' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function Footer(props) {
    const classes = useStyles();

    return (
        <Grid maxWidth="md" component="footer" className={classes.footer}>
            <Grid container spacing={4} justify="center">
                <Grid item xs={6} sm={3} key="sayHi">
                    <ul>
                        <li key="instagram">
                            <a target="_blank" href="https://www.instagram.com/chego.app/" variant="subtitle1" color="textSecondary">
                                Instagram
                            </a>
                        </li>
                        <li key="messenger">
                            <a target="_blank" href="https://m.me/chegoapp/" variant="subtitle1" color="textSecondary">
                                Messenger
                            </a>
                        </li>
                        <li key="whatsapp">
                            <a target="_blank" href="https://wa.me/14088312330?text=Hello!%0A%0A" variant="subtitle1" color="textSecondary">
                                WhatsApp
                            </a>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={6} sm={3} key="sendFeedback">
                    <ul>
                        <li key="sendFeedback">
                            <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe99oi3pvKJSmCNzActGeoOI8KHCbrSeAcE4_B7KIZOGT2J8g/viewform?usp=sf_link" variant="subtitle1" color="textSecondary">
                                Send Feedback
                            </a>
                        </li>
                        <li key="manager">
                            <a target="_blank" href="https://manager.chego.app" variant="subtitle1" color="textSecondary">
                                Add menu card
                            </a>
                        </li>
                        <li key="availability">
                            <Link to="/availability" variant="subtitle1" color="textSecondary">
                                Availability
                            </Link>
                        </li>
                        <li key="privacy">
                            <Link to="/privacy" variant="subtitle1" color="textSecondary">
                                Privacy
                            </Link>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={6} sm={3} key="sayHi">
                    <Copyright />
                </Grid>
            </Grid>
        </Grid>
    );
}