import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';
// import promiseMiddleware from 'redux-promise-middleware';

function configureStore(initialState) {
    // Build the middleware for intercepting and dispatching navigation actions
    /* eslint-disable no-underscore-dangle */
    const store = createStore(reducers,
        initialState);
    /* eslint-enable */
    return store;
}

const initialStateProps = {
}

const store = configureStore(initialStateProps);
export default store;
