import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { appActions } from './../state/actions/app';
import { connect, useSelector } from "react-redux";
import Carousel, { Modal, ModalGateway } from 'react-images';

const useStyles = makeStyles((theme) => ({
    menuPage: {
        borderRadius: 15,
        width: 192,
        height: 256,
        [theme.breakpoints.only('xs')]: {
            width: 128,
            height: 144
        },
        [theme.breakpoints.only('sm')]: {
            width: 192,
            height: 256
        },
    },
    cardsContent: {
        justifyContent: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(2),
        [theme.breakpoints.only('xs')]: {
            spacing: 5,
        },
        [theme.breakpoints.only('sm')]: {
            spacing: 10
        },
        [theme.breakpoints.only('md')]: {
            spacing: 10
        },
        [theme.breakpoints.only('lg')]: {
            spacing: 10
        },
    },
}));

function ImagesList(props) {
    const classes = useStyles();
    const menuImages = useSelector(state => state.app.menuImages);
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const handleClick = (event, val) => {
        const name = event.currentTarget.name;
        const index = parseInt(name.split('_')[1]);
        setModalOpen(true);
        setCurrentIndex(index);
    };

    const toggleModal = (event, val) => {
        setModalOpen(false);
    };

    // Get from map and push into result
    let menuCmps = [];
    let fullImages = [];

    if (menuImages && menuImages.length) {
        // Sort images based on position
        menuImages.sort((a, b) => (parseInt(a.position) > parseInt(b.position)) ? 1 : ((parseInt(a.position) < parseInt(b.position)) ? -1 : 0));

        for (var i = 0; i < menuImages.length; i++) {
            var menuImage = menuImages[i];
            let key = "card_" + i;
            if (menuImage) {
                fullImages.push({
                    source: menuImage.image_url,
                    caption: ''
                });
                menuCmps.push(
                    <Grid item align="center" xs={4} md={3.5} sm={3.5} lg={4}>
                        <Card className={classes.menuPage} align="center">
                            <CardActionArea>
                                <img
                                    name={key} data-id={key} id={key} align="center"
                                    className={classes.menuPage}
                                    src={menuImage.thumbnail_url}
                                    onClick={handleClick}
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                );
            }
        }
    }

    return (
        <React.Fragment>
            {menuCmps.length > 0 &&
                <Container>
                    <Typography variant="h4" align="center" color="textPrimary">
                        Menu
                    </Typography>
                    <Grid className={classes.cardsContent} container spacing={4} elevation={1}>
                        {menuCmps}
                    </Grid>
                    <ModalGateway className="carousel">
                        {isModalOpen ? (
                            <Modal onClose={toggleModal} allowFullscreen={true}>
                                <Carousel views={fullImages} currentIndex={currentIndex} />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </Container>
            }
        </React.Fragment>
    );
}

export default connect(null, appActions)(ImagesList);
