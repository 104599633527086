import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../PromoUI/Footer.js';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import lightBlue from "@material-ui/core/colors/lightBlue";
import orange from "@material-ui/core/colors/orange";
import grey from '@material-ui/core/colors/grey';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    appIcon: {
        marginRight: theme.spacing(1),
        alignItems: 'center',
        position: 'static'
    },
    appBar: {
        position: 'relative',
        background: grey[50],
        zIndex: 1
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
        minHeight: 500,
    },
    heroButtons: {
        marginTop: theme.spacing(2),
    },
    openButton: {
        backgroundColor: orange[500],
        '&:hover': {
            backgroundColor: orange[100],
        },
        marginTop: 20,
        marginBottom: 30,
        alignItems: 'bottom',
        color: 'white'
    },
    message: {
        marginTop: theme.spacing(40)
    },
    mainView: {
        backgroundColor: grey[100],
        paddingBottom: theme.spacing(20),
        minHeight: 550,
        flex: 'row'
    },
}));

export default function NoMenuCardPage(props) {
    const classes = useStyles();
    const { restaurantId } = props;

    return (
        <React.Fragment>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <Grid key="icon" className={classes.appIcon} item>
                        <img width="32" height="32" alt="Chego logo" alt="Chego logo containing a menu card and a fork."
                            src="https://storage.googleapis.com/palate-1301.appspot.com/ChegoIcon.png" />
                    </Grid>
                    <Typography color="textPrimary" variant="h6" className={classes.title}>
                        Chego
                        </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <Typography variant="h4" color="textPrimary" align="center" gutterBottom>
                        Sorry! Chego doesn't have this menu card.
                    </Typography>
                    <div className={classes.heroButtons}>
                        <Grid container spacing={2} justify="center">
                            <Grid item>
                                <Button variant="contained" className={classes.openButton}
                                    target="_blank"
                                    href={"https://manager.chego.app/menucards/" + restaurantId}
                                    startIcon={<OpenInNewIcon />}>
                                    Add Menu Card
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
            <Footer/>
        </React.Fragment>
    );
}