import React, { Component } from 'react';
import { Button, Grid, Header, Image } from 'semantic-ui-react';

class AndroidLoginSuccessPage extends Component {

  constructor(props, context) {
    super(props, context);
    var params = window.location.search;
    var ckString = params.split('?ckWebAuthToken=')[1];
    if (ckString) {
        var ckStrings = ckString.split('&ckSession=');
        var ckWebAuthToken = ckStrings[0];
        var ckSession = ckStrings[1];
        var href = "intent://scan/#Intent;scheme=happy;package=com.happy.fitl;";
        var txt = "S.android.intent.extra.CKWEBAUTHTOKEN=" + ckWebAuthToken+";";
        var subject = "S.android.intent.extra.CKSESSION="+ ckSession;
        var hrefData = "" + href + txt + subject + ";end";
        this.state = {
          ckWebAuthToken: ckWebAuthToken,
          ckSession: ckSession,
          hrefData: hrefData
        }
    }
  }

  render() {
    debugger;
    let hrefData = this.state.hrefData;
    let redirect_url = <a id='redirect_url' href={hrefData}>Back to app</a>;
    return (
      <div>
          <Grid padded centered>
              <Grid.Row>
                  <div className="hero-container">
                    <Header as="h1" size="huge" content="Title" subheader="Test">
                        <div className="App-image">
                          <Image circular src="https://storage.googleapis.com/palate-1301.appspot.com/ChegoIcon.png"
                          height="100" width="100"/>
                        </div>
                        Chego
                        <Header as="h6">Successfully logged</Header>
                    </Header>
                  </div>
              </Grid.Row>
              <Grid.Row stackable>
                  {redirect_url}
            </Grid.Row>
          </Grid>
      </div>
    );
  }
}

export default AndroidLoginSuccessPage;
