import React, { Component } from 'react';
import { Button, Grid, Header, Image } from 'semantic-ui-react';

class AndroidLogin extends Component {

  constructor(props) {
    super(props);
    var me = this;
    let CloudKit = window.CloudKit;
    CloudKit.configure({
      containers: [{
          containerIdentifier: 'iCloud.com.happy.Fitl',
          apiTokenAuth: {
              apiToken: 'fd916803519af093ab0b9f93d70fd271df3b8ec14a5e8164511493352ecfb7bb',
              signInButton: {
                id: 'apple-sign-in-button',
                theme: 'black' // Other options: 'white', 'white-with-outline'.
              },

              signOutButton: {
                id: 'apple-sign-out-button',
                theme: 'black'
              }
          },
          environment: 'development'
      }],
    });
    this.container = CloudKit.getDefaultContainer();
    this.CloudKit = CloudKit;
    window.addEventListener('message', function(e) {
        me.ckSession = encodeURI(e.data.ckSession);
    });
    this.gotoUnauthenticatedState = this.gotoUnauthenticatedState.bind(this);
    this.gotoAuthenticatedState = this.gotoAuthenticatedState.bind(this);
  }

  componentDidMount() {
    var me = this;
    this.container.setUpAuth()
    .then(function(userInfo) {
      // Either a sign-in or a sign-out button was added to the DOM.
      // userInfo is the signed-in user or null.
      if(userInfo) {
        me.gotoAuthenticatedState(userInfo);
      } else {
        me.gotoUnauthenticatedState();
      }
    });
  }

  gotoAuthenticatedState(userInfo) {
    var me = this;
    if(userInfo.isDiscoverable) {
      let firstName = userInfo.firstName;
      let lastName = userInfo.lastName;
    } else {
      let recordName = userInfo.userRecordName;
      var href = "intent://scan/#Intent;scheme=happy;package=com.happy.fitl;";
      var txt = "S.android.intent.extra.RECORDNAME=" + userInfo.userRecordName+";";
      var subject = "S.android.intent.extra.CKSESSION="+this.ckSession;
      var hrefData = href + txt + subject + ";end";
      document.getElementById('redirect_url').setAttribute('href', hrefData);
      var redirect = document.getElementById('redirect_url');
      alert(redirect.getAttribute('href'));
      redirect.innerHTML = hrefData;
      window.open(hrefData,"_self");
      redirect.click();
    }
    this.container
      .whenUserSignsOut()
      .then(me.gotoUnauthenticatedState);
  }

  gotoUnauthenticatedState(error) {
    var me = this;
    if(error && error.ckErrorCode === 'AUTH_PERSIST_ERROR') {
      // showDialogForPersistError();
    }
    this.container
      .whenUserSignsIn()
      .then(me.gotoAuthenticatedState)
      .catch(me.gotoUnauthenticatedState);
  }

  render() {
    return (
      <div>
          <Grid padded centered>
              <Grid.Row>
                  <div className="hero-container">
                    <Header as="h1" size="huge" content="Title" subheader="Test">
                        <div className="App-image">
                          <Image circular src="https://storage.googleapis.com/palate-1301.appspot.com/ChegoIcon.png"
                          height="100" width="100"/>
                        </div>
                        Chego
                        <Header as="h2">Chego uses iCloud to sync your data
                        privately and securely between your devices.</Header>
                    </Header>
                  </div>
              </Grid.Row>
          </Grid>
          <Grid verticalAlign='middle' columns={1} centered>
              <Grid.Row stackable>
                <Grid.Column>
                  <div className="apple-button" id="apple-sign-in-button"></div>
                  <div className="apple-button" id="apple-sign-out-button"></div>
                </Grid.Column>
                <Grid.Column>
                  <a id="redirect_url"  href="#"></a>
                </Grid.Column>
            </Grid.Row>
          </Grid>
      </div>
    );
  }
}

export default AndroidLogin;
